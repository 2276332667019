import { BrandfolderHexColors } from '@brandfolder/react';
import { whitelabelPresenter } from '@brandfolder/utilities';

import { Theme, ThemeFirestore, ThemePost } from '@typings/theme';

export const defaultFontFamily = 'Circular-Book';

export const defaultThemePost: ThemePost = {
  body: {
    backgroundColor: BrandfolderHexColors.White,
    color: BrandfolderHexColors.Text
  },
  brandguideKey: '',
  color: {
    displayCmyk: true,
    displayHex: true,
    displayHsl: true,
    displayRgb: true
  },
  custom: {
    css: ''
  },
  font: {
    fontFamily: defaultFontFamily
  },
  image: {
    faviconHref:
      'https://cdn.bfldr.com/27C9EC93/at/gtw7psb5p5c79598r36n7fm/favicon-32x32.png?auto=webp&format=png',
    heroSrc:
      'https://cdn.bfldr.com/27C9EC93/at/4qfkv958m75bt33kh3qxm7bg/brandguide-hero-image-placeholder.png?auto=webp&format=png'
  },
  link: {
    backgroundColorActive: BrandfolderHexColors.DirtyMarshmallow,
    collapsePageSections: true,
    color: BrandfolderHexColors.Text,
    colorHover: BrandfolderHexColors.DirtyMarshmallow,
    stickyNav: true
  }
};

const defaultThemeFirestore: ThemeFirestore = {
  ...defaultThemePost,
  createdAt: new Date().toISOString(),
  deletedAt: null,
  updatedAt: new Date().toISOString()
};

export const defaultTheme: Theme = {
  ...defaultThemeFirestore,
  key: ''
};

export const defaultGettyThemePost: ThemePost = {
  ...defaultThemePost,
  image: {
    faviconHref: whitelabelPresenter('getty').faviconCdnUrl,
    heroSrc:
      'https://cdn.bfldr.com/27C9EC93/at/xtt837nj449pbqg6nr92k8/getty-brandguide-header-image.svg?auto=webp&format=png'
  }
};

const defaultGettyThemeFirestore: ThemeFirestore = {
  ...defaultGettyThemePost,
  createdAt: new Date().toISOString(),
  deletedAt: null,
  updatedAt: new Date().toISOString()
};

export const defaultGettyTheme: Theme = {
  ...defaultGettyThemeFirestore,
  key: ''
};
