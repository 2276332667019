import { createContext, Dispatch, SetStateAction } from 'react';

import { defaultTheme } from '@constants/theme';
import { Theme } from '@typings/theme';

interface ThemeContextState {
  isLight: boolean;
  setTheme: Dispatch<SetStateAction<Theme>>;
  theme: Theme;
}

export const ThemeContext = createContext<ThemeContextState>({
  isLight: true,
  theme: defaultTheme,
  setTheme: () => {}
});
