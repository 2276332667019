import {
  BrandfolderGlobalComponentStyles,
  BrandfolderGlobalFonts,
  BrandfolderHexColors
} from '@brandfolder/react';
import classNames from 'classnames';
import { FunctionComponent, HTMLAttributes, ReactNode, useContext, useEffect } from 'react';
import styled, { css } from 'styled-components';

import { BackToTop } from '@components/nav';
import { OAuthContext } from '@context/oauth';
import { PermissionLevelContext } from '@context/permission-level';
import { PreviewContext } from '@context/preview';
import { BrandguideGlobalStyles } from '@styles/global';

interface PageProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  home?: boolean;
}

const UnstyledPage: FunctionComponent<PageProps> = (props) => {
  const { children, home = false, ...otherProps } = props;

  const { authed } = useContext(OAuthContext);
  const { editable } = useContext(PermissionLevelContext);
  const { preview } = useContext(PreviewContext);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        document.body.classList.add('has-scrolled');
      } else {
        document.body.classList.remove('has-scrolled');
      }
    };

    if (!home) {
      window.addEventListener('scroll', handleScroll, { passive: true });
      return () => window.removeEventListener('scroll', handleScroll);
    }
  }, [home]);

  const editing = editable && !preview;

  return (
    <>
      <BrandguideGlobalStyles />

      {(authed || !editable) && (
        <>
          <BrandfolderGlobalComponentStyles />
          <BrandfolderGlobalFonts />
        </>
      )}

      <div
        {...otherProps}
        className={classNames({
          page: true,
          'page--authed': authed,
          'page--editing': editing,
          'page--home': home,
          'page--preview': preview,
          [`${otherProps?.className}`]: !!otherProps?.className
        })}
      >
        {children}
        {!home && <BackToTop />}
      </div>
    </>
  );
};

const mainMinWidth = css`
  min-width: 768px;
`;

const headerAndMain = css`
  grid-template-areas:
    'header'
    'main';
  grid-template-columns: 1fr;
  grid-template-rows: 78px 1fr;
`;

const StyledPage = styled(UnstyledPage)`
  display: grid;
  grid-template-areas: 'main';
  grid-template-rows: 1fr;
  min-height: 100vh;

  /* guest authed but not editing */
  &.page--authed {
    ${headerAndMain}
  }

  /* admin authed and editing (not preview) */
  &.page--editing:not(.page--preview) {
    grid-template-areas:
      'header header'
      'aside main';
    grid-template-columns: 65px 1fr;
    grid-template-rows: 78px 1fr;
    ${mainMinWidth}
  }

  /** admin authed and home brandguide list */
  &.page--home.page--authed {
    ${headerAndMain}
    ${mainMinWidth}
  }

  &.page--home {
    background-color: ${BrandfolderHexColors.Marshmallow};
  }
`;

export const Page: FunctionComponent<PageProps> = (props) => {
  return <StyledPage {...props} />;
};
