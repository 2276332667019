import { FontCategories } from '@enums/font';
import { Font } from '@typings/font';

/**
 * Based on W3Schools web-safe fonts list
 * https://www.w3schools.com/cssref/css_websafe_fonts.php
 */
export const FONT_FAMILY_FORMATS: Font[] = [
  {
    category: FontCategories.SansSerif,
    family: 'Arial'
  },
  {
    category: FontCategories.Cursive,
    family: 'Brush Script MT'
  },
  {
    category: FontCategories.SansSerif,
    family: 'Circular-Book'
  },
  {
    category: FontCategories.Monospace,
    family: 'Courier New'
  },
  {
    category: FontCategories.Serif,
    family: 'Garamond'
  },
  {
    category: FontCategories.Serif,
    family: 'Georgia'
  },
  {
    category: FontCategories.SansSerif,
    family: 'Tahoma'
  },
  {
    category: FontCategories.Serif,
    family: 'Times New Roman'
  },
  {
    category: FontCategories.SansSerif,
    family: 'Trebuchet MS'
  },
  {
    category: FontCategories.SansSerif,
    family: 'Verdana'
  }
];
