import { fetchJson } from '@brandfolder/react';
import { Dispatch, SetStateAction } from 'react';

import { ApiDatum } from '@typings/api';
import { Brandguide } from '@typings/brandguide';
import { Page, PageGet } from '@typings/page';
import logger from '@utilities/logger';
import { sanitizer } from '@utilities/sanitize';

/**
 * Get the page `<title>` for a Brandguide page.
 *
 * @param brandguide Brandguide | null
 * @param page Page | null
 * @returns string
 */
export const getPageTitle = (brandguide: Brandguide | null, page: Page | null): string => {
  const brandguideName = brandguide?.name || '';
  const divider = brandguide && page ? ' | ' : '';
  const pageName = page ? page.name : '';
  return `${brandguideName}${divider}${pageName}`;
};

const reservedPages = new Set(['404', '500']);

/**
 * Get if a page slug is reserved or not.
 *
 * @param slug string
 * @returns boolean
 */
export const getIsPageReserved = (slug: string): boolean => {
  return reservedPages.has(slug);
};

/**
 * Get the page slug from Next.js page context.query
 * or false if the page slug has not been specified
 *
 * @example https://brandguide.customer.com => false
 * @example https://brandguide.customer.com/logos => 'logos'
 *
 * @example https://brandguides.brandfolder.com/brandfolder => false
 * @example https://brandguides.brandfolder.com/brandfolder/logos => 'logos'
 *
 * @param isCname boolean
 * @param queries string[] | undefined
 * @returns string | false
 */
export const getPageSlug = (isCname: boolean, queries?: string[]): string | false => {
  // cname brandguide at home, like https://brandguide.smartsheet.com
  if (isCname && !queries) {
    return false;
  }
  if (isCname && queries && queries[0]) {
    return queries[0];
  }
  if (!isCname && queries && queries[1]) {
    return queries[1];
  }
  return false;
};

/**
 * Update a page within a list of pages
 *
 * @param page Page
 * @param pages Page[]
 * @returns Page[]
 */
export const updatePage = (page: Page, pages: Page[]): Page[] => {
  return pages.map((p) => (p.key === page.key ? { ...page } : { ...p }));
};

/**
 * Update multiple pages within a list of pages
 *
 * @param updatedPages Page[]
 * @param pages Page[]
 * @returns Page[]
 */
export const updatePages = (updatedPages: Page[], pages: Page[]): Page[] => {
  const updatedPagesKeys = new Set(updatedPages.map((p) => p.key));
  const changedPages: Page[] = [];
  pages.forEach((p) => {
    if (updatedPagesKeys.has(p.key)) {
      const updatedPage = updatedPages.find((up) => up.key === p.key);
      if (updatedPage) {
        changedPages.push(updatedPage);
      }
    } else {
      changedPages.push(p);
    }
  });
  return changedPages;
};

/**
 * Returns pages with positions adjusted according to the reorder action
 * that just took place. Assumes that dropping a page on to the drag space of another page
 * should place the dropped page _ahead_ (with a smaller position) of the drag space page
 *
 * @param droppedPage Page
 * @param draggedPage Page
 * @param pages Page[]
 * @returns [Page[], Page[]] - full set of pages and just those that have had positions changed
 */
export const reorderPages = (
  droppedPage: Page,
  draggedPage: Page,
  pages: Page[]
): [Page[], Page[]] => {
  let reorderedPages: Page[];

  if (droppedPage.position < draggedPage.position) {
    // page has been moved down
    const earlierPages = pages.slice(droppedPage.position + 1, draggedPage.position + 1);
    // decrease position of prior pages by one since a page has been moved down
    reorderedPages = earlierPages.map((p) => ({ ...p, position: p.position - 1 }));
  } else {
    // page has been moved up
    const laterPages = pages.slice(draggedPage.position, droppedPage.position);
    // increase position of later pages by one since a page has been moved up
    reorderedPages = laterPages.map((p) => ({ ...p, position: p.position + 1 }));
  }

  const reorderedDroppedPage = { ...droppedPage, position: draggedPage.position };
  reorderedPages.push(reorderedDroppedPage);
  return [
    updatePages(reorderedPages, pages).sort((a, b) => a.position - b.position),
    reorderedPages
  ];
};

/**
 * Insert a new page into a list of pages
 *
 * @param page Page
 * @param pages Page[]
 * @returns Page[]
 */
export const insertPage = (page: Page, pages: Page[]): Page[] => {
  return [
    ...pages.slice(0, page.position).map((p, i) => ({ ...p, position: i })),
    { ...page },
    ...pages.slice(page.position).map((p, i) => ({ ...p, position: page.position + i + 1 }))
  ];
};

/**
 * Remove a page within a list of pages
 *
 * @param page Page
 * @param pages Page[]
 * @returns Page[]
 */
export const removePage = (page: Page, pages: Page[]): Page[] => {
  return pages
    .filter((p) => p.key !== page.key)
    .map((p) => (p.position < page.position ? p : { ...p, position: p.position - 1 }));
};

/**
 * Sanitize page
 *
 * @param page Partial<Page>
 * @returns Partial<Page>
 */
export const sanitizePage = (page: Partial<Page> = {}): Partial<Page> => {
  const { name, slug } = page;

  return {
    ...page,
    ...(typeof name === 'string' && { name: sanitizer(name) }),
    ...(typeof slug === 'string' && { slug: sanitizer(slug) })
  };
};

interface GetPageBySlugOptions {
  brandguide: Brandguide;
  isCname: boolean;
  page: Page;
  hash?: string;
  search?: string; // window.location.search
}

/**
 * Get `href` for Next.js nav `<Link>`.
 * Intentionally relative links so this works with any URL.
 *
 * @param options GetPageBySlugOptions
 * @returns string
 */
export const getPageHref = (options: GetPageBySlugOptions): string => {
  const { brandguide, hash, isCname, page, search } = options;

  // IMPORTANT: `hash` must be last
  return isCname
    ? `/${page.slug}${search || ''}${hash || ''}`
    : `/${brandguide.slug}/${page.slug}${search || ''}${hash || ''}`;
};

interface BulkPatchPagePositionsOptions {
  pages: Page[];
  pagesToUpdate: Page[];
  setPages: Dispatch<SetStateAction<Page[]>>;
  onComplete?: () => void;
  onError?: () => void;
}

/**
 * Submit multiple position PATCH requests
 *
 * @param options BulkPatchPagePositionsOptions
 *
 * @returns void
 */
export const bulkPatchPagePositions = async ({
  onComplete,
  onError,
  pages,
  pagesToUpdate,
  setPages
}: BulkPatchPagePositionsOptions): Promise<void> => {
  const positionUpdatePromises = pagesToUpdate.map((p) => {
    return fetchJson<ApiDatum<PageGet>>({
      body: { data: { attributes: { position: p.position } } },
      headers: {
        'Content-Type': 'application/json',
        'x-brandguide-key': p.brandguideKey
      },
      method: 'PATCH',
      url: `/api/pages/${p.key}`
    });
  });

  try {
    const results = await Promise.all(positionUpdatePromises);
    const completePages = results.map(({ data }): Page => {
      const matchingPage = pages.find((p) => p.key === data.id);
      if (!matchingPage)
        throw new Error('Could not find matching page entry for page PATCH response');
      return {
        ...matchingPage,
        ...data.attributes
      };
    });

    const updatedPages = updatePages(completePages, pages);
    setPages(updatedPages.sort((a, b) => a.position - b.position));

    if (onComplete) {
      onComplete();
    }
  } catch (error) {
    logger.error(error);
    if (onError) {
      onError();
    }
  }
};

/**
 * Get whether a page is the current page
 * @param currentPage Page
 * @param page boolean
 * @returns boolean
 */
export const getIsCurrentPage = (currentPage: Page, page: Page): boolean => {
  return currentPage.key === page.key;
};

/**
 * Get the first page by position from array of pages.
 * @param page Page
 * @returns boolean
 */
export const getIsFirstPage = (page: Page): boolean => {
  return page.position === 0;
};

/**
 * Get whether if a page is the homepage
 * @param page Page
 * @param isCname boolean
 * @param asPath string
 * @returns boolean
 */
export const getIsHomepage = (page: Page, isCname: boolean, asPath: string): boolean => {
  if (!getIsFirstPage(page)) return false;
  const queries = asPath.split('/').slice(1);
  return getPageSlug(isCname, queries) === false;
};
