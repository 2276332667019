import {
  BrandfolderHexColors,
  FontIcon,
  FontIcons,
  OnlyForScreenReader,
  usePrefersReducedMotion
} from '@brandfolder/react';
import classNames from 'classnames';
import { AnchorHTMLAttributes, FunctionComponent } from 'react';
import styled from 'styled-components';

interface BackToTopProps extends Omit<AnchorHTMLAttributes<HTMLAnchorElement>, 'href'> {}

const UnstyledBackToTop: FunctionComponent<BackToTopProps> = (props) => {
  const prefersReducedMotion = usePrefersReducedMotion();

  return (
    <a
      {...props}
      className={classNames({
        nav__button: true,
        'back-to-top': true,
        'back-to-top--prefers-reduced-motion': prefersReducedMotion,
        [`${props?.className}`]: !!props?.className
      })}
      href="#top" // #top lives in _document.tsx
    >
      <FontIcon aria-hidden icon={FontIcons.ArrowUp} />
      <OnlyForScreenReader>Back To Top</OnlyForScreenReader>
    </a>
  );
};

const StyledBackToTop = styled(UnstyledBackToTop)`
  align-items: center;
  background-color: ${BrandfolderHexColors.White};
  border: 1px solid #353a4a;
  border-radius: 4px;
  bottom: -64px;
  color: #353a4a;
  cursor: pointer;
  display: flex;
  height: 48px;
  justify-content: center;
  opacity: 0;
  overflow: hidden;
  position: fixed;
  right: 16px;
  text-decoration: none;
  transition:
    background-color 0.2s ease,
    bottom 0.2s ease,
    opacity 0.5s ease;
  width: 48px;

  &:focus,
  &:hover {
    background-color: ${BrandfolderHexColors.DirtyMarshmallow};
  }

  &.back-to-top--prefers-reduced-motion {
    transition: none;
  }

  .has-scrolled & {
    bottom: 16px;
    opacity: 1;
  }

  @media (min-width: 1025px) {
    bottom: -64px !important;
  }
`;

export const BackToTop: FunctionComponent<BackToTopProps> = (props) => {
  return <StyledBackToTop {...props} />;
};
