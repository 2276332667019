/**
 * Checks if the current URL hash matches the given section ID
 * @param id string | undefined
 * @returns boolean
 */
export const hashMatchesSection = (id?: string): boolean => {
  if (typeof window === 'undefined') return false;

  const { hash } = window.location;
  return !!hash && !!id && hash === `#${id}`;
};

/**
 * Scroll to section. NOTE: `id` must NOT contain a leading hash (#).
 * @param id string | undefined
 * @returns void
 */
export const scrollToSection = (id?: string): void => {
  if (typeof window === 'undefined') return;

  if (id) {
    const section = document.querySelector(`[id='${id}']`);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  }
};

/**
 * Scroll to top of page.
 * @returns void
 */
export const scrollToPageTop = (): void => {
  if (typeof window === 'undefined') return;

  document.body.scrollIntoView({ behavior: 'smooth' });
};
