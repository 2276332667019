/**
 * https://developer.mozilla.org/en-US/docs/Web/CSS/font-family#syntax
 */
export enum FontCategories {
  Cursive = 'cursive',
  Fantasy = 'fantasy',
  Monospace = 'monospace',
  SansSerif = 'sans-serif',
  Serif = 'serif'
}
