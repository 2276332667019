import { css } from 'styled-components';

/**
 * Slightly modified from: https://www.scottohara.me/blog/2017/04/14/inclusively-hidden.html
 */
export const screenReaderOnly = css`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const undoScreenReaderOnly = css`
  clip: unset;
  clip-path: unset;
  height: unset;
  margin: unset;
  overflow: unset;
  padding: unset;
  position: unset;
  white-space: unset;
  width: unset;
`;
