import {
  BrandfolderGlobalFonts,
  BrandfolderGlobalIconFont,
  BrandfolderHexColors,
  ButtonLink,
  ButtonLooks,
  FontAlignments,
  FontIcons,
  StandardHeading,
  StandardText
} from '@brandfolder/react';
import classNames from 'classnames';
import Image from 'next/image';
import { FunctionComponent, HTMLAttributes } from 'react';
import styled from 'styled-components';

import { StatusCodes } from '@enums/status-codes';
import { BrandguideGlobalStyles } from '@styles/global';

interface PageErrorProps extends HTMLAttributes<HTMLDivElement> {
  statusCode?: StatusCodes;
}

const UnstyledPageError: FunctionComponent<PageErrorProps> = (props) => {
  const { statusCode = StatusCodes.NotFound, ...otherProps } = props;

  const image =
    statusCode === StatusCodes.NotFound
      ? {
          height: 218,
          src: 'https://cdn.brandfolder.io/27C9EC93/at/qent7i-f8t3eg-auh49h/404-page.auto',
          width: 800
        }
      : {
          height: 308,
          src: 'https://cdn.brandfolder.com/server_error_car.png',
          width: 400
        };

  const heading = statusCode === StatusCodes.NotFound ? 'Page Not Found' : 'Internal Server Error';
  const paragraph =
    statusCode === StatusCodes.NotFound
      ? 'This is likely due to a mistyped URL or an out-of-date link.'
      : 'Please refresh the page and try again.';

  return (
    <>
      <BrandguideGlobalStyles />
      <BrandfolderGlobalFonts />
      <BrandfolderGlobalIconFont />

      <div
        {...otherProps}
        className={classNames({
          page: true,
          page__error: true,
          [`${otherProps?.className}`]: !!otherProps?.className
        })}
      >
        <main>
          <Image alt="" height={image.height} src={image.src} width={image.width} />
          <StandardHeading align={FontAlignments.Center} className="heading">
            {heading}
          </StandardHeading>
          <StandardText align={FontAlignments.Center} className="paragraph">
            {paragraph}
          </StandardText>
          <StandardText align={FontAlignments.Center}>
            <ButtonLink
              endIcon={FontIcons.NewTab}
              endIconProps={{
                iconSize: 12
              }}
              href="https://help.smartsheet.com/brandfolder"
              look={ButtonLooks.Default}
              target="_blank"
            >
              Support
            </ButtonLink>
          </StandardText>
        </main>
      </div>
    </>
  );
};

const StyledPageError = styled(UnstyledPageError)`
  align-items: center;
  background-color: ${BrandfolderHexColors.White};
  display: grid;
  justify-content: center;
  min-height: 100vh;
  padding: 24px;
  text-align: center;

  .heading {
    font-size: 28px;
    margin: 8px 0;
  }

  .paragraph {
    margin-bottom: 24px;
  }
`;

export const PageError: FunctionComponent<PageErrorProps> = (props) => {
  return <StyledPageError {...props} />;
};
