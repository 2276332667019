import { BrandfolderHexColors } from '@brandfolder/react';
import { createGlobalStyle } from 'styled-components';

import { defaultFontFamily } from '@constants/theme';
import { screenReaderOnly } from '@styles/screen-reader';

export const BrandguideGlobalStyles = createGlobalStyle`
  html {
    scroll-behavior: smooth;
  }

  #__next {
    min-height: 100vh;
  }
  
  h1 { font-size: 24px }
  h2 { font-size: 22px }
  h3 { font-size: 20px }
  h4 { font-size: 18px }
  h5 { font-size: 16px }
  h6 { font-size: 14px }

  audio, canvas, iframe, picture, svg, video {
    display: block;
    max-width: 100%;
  }

  img {
    height: auto;
  }

  .mce-content-body {
    padding: 8px;

    &:focus-visible {
      outline: 1px solid ${BrandfolderHexColors.Text};
    }
  }

  .tox-menu {
    max-height: 250px !important;
  }

  .tox .tox-tbtn[title="Font Family"] {
    background: #f7f7f7;
    margin-inline-end: 4px;
    width: 130px !important;

    &:hover {
      background: #cce2fa;
    }

    .tox-tbtn__select-label {
      width: 7em;
    }
  }

  .toaster {
    font-family: '${defaultFontFamily}', sans-serif;
  }

  .screen-reader-only {
    ${screenReaderOnly}
  }
`;
