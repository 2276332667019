import { FONT_FAMILY_FORMATS } from '@constants/font';
import { Theme, ThemeBody, ThemeCustom, ThemeFont, ThemeImage, ThemeLink } from '@typings/theme';
import { sanitizer } from '@utilities/sanitize';

/**
 * Sanitize theme body
 *
 * @param themeBody Partial<ThemeBody>
 * @returns Partial<ThemeBody>
 */
export const sanitizeThemeBody = (themeBody: Partial<ThemeBody> = {}): Partial<ThemeBody> => {
  const { backgroundColor, color } = themeBody;

  return {
    ...themeBody,
    ...(typeof backgroundColor === 'string' && { backgroundColor: sanitizer(backgroundColor) }),
    ...(typeof color === 'string' && { color: sanitizer(color) })
  };
};

/**
 * Sanitize theme custom
 *
 * @param themeCustom Partial<ThemeCustom>
 * @returns Partial<ThemeCustom>
 */
export const sanitizeThemeCustom = (
  themeCustom: Partial<ThemeCustom> = {}
): Partial<ThemeCustom> => {
  const { css } = themeCustom;

  return {
    ...themeCustom,
    ...(typeof css === 'string' && { css: sanitizer(css) })
  };
};

/**
 * Sanitize theme font
 *
 * @param themeFont Partial<ThemeFont>
 * @returns Partial<ThemeFont>
 */
export const sanitizeThemeFont = (themeFont: Partial<ThemeFont> = {}): Partial<ThemeFont> => {
  const { fontFamily } = themeFont;

  return {
    ...themeFont,
    ...(typeof fontFamily === 'string' && { fontFamily: sanitizer(fontFamily) })
  };
};

/**
 * Sanitize theme image
 *
 * @param themeImage Partial<ThemeImage>
 * @returns Partial<ThemeImage>
 */
export const sanitizeThemeImage = (themeImage: Partial<ThemeImage> = {}): Partial<ThemeImage> => {
  const { faviconHref, heroSrc } = themeImage;

  return {
    ...themeImage,
    ...(typeof faviconHref === 'string' && { faviconHref: sanitizer(faviconHref) }),
    ...(typeof heroSrc === 'string' && { heroSrc: sanitizer(heroSrc) })
  };
};

/**
 * Sanitize theme link
 *
 * @param themeLink Partial<ThemeLink>
 * @returns Partial<ThemeLink>
 */
export const sanitizeThemeLink = (themeLink: Partial<ThemeLink> = {}): Partial<ThemeLink> => {
  const { backgroundColorActive, color, colorHover } = themeLink;

  return {
    ...themeLink,
    ...(typeof backgroundColorActive === 'string' && {
      backgroundColorActive: sanitizer(backgroundColorActive)
    }),
    ...(typeof color === 'string' && { color: sanitizer(color) }),
    ...(typeof colorHover === 'string' && { colorHover: sanitizer(colorHover) })
  };
};

/**
 * Generate CSS custom properties from Theme
 * @param theme Theme
 * @returns string
 */
export const generateCssCustomProperties = (theme: Theme): string => {
  return `
    :root {
      --body-background-color: ${theme.body.backgroundColor};
      --body-color: ${theme.body.color};
      --font-family: '${theme.font.fontFamily}', ${
        FONT_FAMILY_FORMATS.find((font) => font.family === theme.font.fontFamily)?.category ||
        'sans-serif'
      };
      --link-color: ${theme.link.color};
      --link-color-hover: ${theme.link.colorHover};
      --link-background-color-active: ${theme.link.backgroundColorActive};
    }
  `;
};

/**
 * Apply CSS custom properties to HTML elements
 * @returns string
 */
export const applyTheme = (): string => {
  return `
    body {
      background-color: var(--body-background-color);
      color: var(--body-color);
      font-family: var(--font-family);
      font-weight: 400;
    }

    h1, h2, h3, h4, h5, h6 {
      font-weight: 500;
    }

    .nav-link {
      color: var(--link-color);
    }

    .nav-link:focus-visible,
    .nav-link:hover {
      background-color: var(--link-color-hover);
    }

    .nav-link--background-active {
      background-color: var(--link-background-color-active);
    }

    .page .nav__button {
      border-color: var(--link-color);
      color: var(--link-color);
    }

    .page .nav__button:focus-visible,
    .page .nav__button:hover {
      background-color: var(--link-color-hover);
      border-color: var(--link-color);
      color: var(--link-color);
    }
  `;
};
