import Head from 'next/head';
import { FunctionComponent, HTMLAttributes, useContext, useEffect, useState } from 'react';

import { FONT_FAMILY_FORMATS } from '@constants/font';
import { defaultFontFamily } from '@constants/theme';
import { ThemeContext } from '@context/theme';
import { Brandguide } from '@typings/brandguide';
import { Page } from '@typings/page';
import { Theme } from '@typings/theme';
import { getPageTitle } from '@utilities/page';
import { applyTheme, generateCssCustomProperties } from '@utilities/theme';

interface PageHeadProps extends HTMLAttributes<HTMLDivElement> {
  brandguide: Brandguide | null;
  page: Page | null;
  theme: Theme;
}

export const PageHead: FunctionComponent<PageHeadProps> = (props) => {
  const { brandguide, page, theme: serverTheme } = props;

  const { theme: clientTheme } = useContext(ThemeContext);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const theme = mounted ? clientTheme : serverTheme;

  return (
    <Head>
      <title>{getPageTitle(brandguide, page)}</title>
      {theme.image.faviconHref && (
        <link data-testid="faviconHref" href={theme.image.faviconHref} rel="icon" />
      )}
      {theme.font.fontFamily &&
        theme.font.fontFamily !== defaultFontFamily &&
        !FONT_FAMILY_FORMATS.map((f) => f.family).includes(theme.font.fontFamily) && (
          <>
            <link
              data-testid="fontsGoogleApisPreconnect"
              href="https://fonts.googleapis.com"
              rel="preconnect"
            />
            <link
              data-testid="fontsGstaticPreconnect"
              href="https://fonts.gstatic.com"
              rel="preconnect"
            />
            {/* eslint-disable-next-line @next/next/no-page-custom-font */}
            <link
              data-testid="fontsGoogleApis"
              href={`https://fonts.googleapis.com/css2?family=${theme.font.fontFamily}:wght@400;500;700&display=swap`}
              rel="stylesheet"
            />
          </>
        )}
      <style>{generateCssCustomProperties(theme)}</style>
      <style>{applyTheme()}</style>
      {theme.custom.css && <style data-testid="customCss">{theme.custom.css}</style>}
    </Head>
  );
};
