import {
  ButtonLooks,
  FontIcons,
  IconButton,
  useMatchMedia,
  usePrefersReducedMotion
} from '@brandfolder/react';
import classNames from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { FunctionComponent, HTMLAttributes, useContext, useState } from 'react';
import AnimateHeight, { Height } from 'react-animate-height';
import styled from 'styled-components';

import { BlockContext } from '@context/blocks';
import { BrandguideContext } from '@context/brandguide';
import { PageContext } from '@context/page';
import { ThemeContext } from '@context/theme';
import { screenReaderOnly, undoScreenReaderOnly } from '@styles/screen-reader';
import { ApiDatum } from '@typings/api';
import { Block } from '@typings/block';
import { Page } from '@typings/page';
import { getBlockDataIsSection, getPageBlocks } from '@utilities/block';
import { getPageHref, updatePage } from '@utilities/page';
import { scrollToPageTop, scrollToSection } from '@utilities/scroll';

interface NavProps extends HTMLAttributes<HTMLDivElement> {}

const UnstyledNav: FunctionComponent<NavProps> = (props) => {
  const { className, ...otherProps } = props;

  const { asPath } = useRouter();
  const prefersReducedMotion = usePrefersReducedMotion();
  const isMobile = useMatchMedia('(max-width: 767px)');

  const { brandguide, isCname } = useContext(BrandguideContext);
  const { blocks, sections, setBlocks, setInitialBlocks } = useContext(BlockContext);
  const { pages, setPage, setPages } = useContext(PageContext);
  const { theme } = useContext(ThemeContext);

  const [height, setHeight] = useState<Height>(0);

  if (!brandguide || !pages || pages.length === 0) return null;

  const handleRouting = async (page: Page, hash?: string) => {
    try {
      const url = `/api/pages/${page.key}?include=blocks`;
      const response = await fetch(url);
      if (response.ok) {
        const json = (await response.json()) as ApiDatum<Page, 'pages', Block, 'blocks'>;

        setPage({ ...page, ...json.data.attributes }, hash ? undefined : () => scrollToPageTop());
        setPages([...updatePage({ ...page, ...json.data.attributes }, pages)]);

        if (json.included && json.included.length > 0) {
          const otherBlocks = blocks.filter((b) => b.pageKey !== page.key);
          const newBlocks = json.included.map((b) => b.attributes);
          setBlocks([...newBlocks, ...otherBlocks], hash ? () => scrollToSection(hash) : undefined);
          setInitialBlocks([...newBlocks, ...otherBlocks]);
        }
      } else {
        throw new Error('Error fetching page while navigating');
      }
    } catch {
      setPage(page);
    }
  };

  const renderSections = (page: Page, active: boolean) => {
    const sectionBlocks = getPageBlocks(sections, page.key);

    if (sectionBlocks.length === 0) return null;

    return (
      <div
        className={classNames({
          nav__sections: true,
          'nav__sections--active': active,
          'section-name-wrapper': true
        })}
      >
        {sectionBlocks.map((section) => {
          const data = getBlockDataIsSection(section.data) ? section.data : null;

          if (!data) return null;

          const href = getPageHref({
            brandguide,
            hash: `#${data.id}`,
            isCname,
            page,
            search: window.location.search
          });

          return (
            <div className="nav__section" key={section.key}>
              <Link
                className={classNames({
                  'nav-link': true,
                  'nav-link--section': true
                })}
                href={href}
                onClick={() => {
                  handleRouting(page, data.id);
                }}
                scroll={false}
                shallow
              >
                {data.name}
              </Link>
            </div>
          );
        })}
      </div>
    );
  };

  const getBackgroundActive = (page: Page, index: number): boolean => {
    const parts = asPath.split('?')[0].split('/');
    // strips section hash from string
    const last = parts.at(-1)?.split('#')[0] || '';
    // we're at a page slug, like https://brandguides.brandfolder.com/foo/bar or https://brandguides.custom.com/bar
    if (page.slug === last) return true;
    // we're at the homepage of a brandguide url, like https://brandguides.brandfolder.com/foo
    if (!isCname && index === 0 && brandguide.slug === last) return true;
    // we're at the homepage of a cname url, like https://brandguides.custom.com
    if (isCname && index === 0 && last === '') return true;
    return false;
  };

  const open = height !== 0;

  return (
    <nav
      {...otherProps}
      className={classNames({
        nav: true,
        'nav--closed': isMobile && !open,
        'nav--collapse-page-sections': theme.link.collapsePageSections,
        'nav--is-mobile': isMobile,
        'nav--prefers-reduced-motion': isMobile && prefersReducedMotion,
        'nav--open': isMobile && open,
        'nav--sticky': theme.link.stickyNav,
        [`${className}`]: !!className
      })}
      id="nav"
    >
      {isMobile && (
        <IconButton
          aria-expanded={open}
          aria-controls="menu"
          className="nav__button"
          icon={open ? FontIcons.Close : FontIcons.Menu}
          id="menu-toggle"
          label="Toggle Navigation Menu"
          look={ButtonLooks.Tertiary}
          onClick={() => setHeight(open ? 0 : 'auto')}
        />
      )}
      <AnimateHeight
        // remove aria attribute when isMobile is true for desktop accessibility
        aria-hidden={isMobile && !open ? true : undefined}
        aria-labelledby={isMobile ? 'menu-toggle' : undefined}
        className={classNames({
          'nav--pages': true
        })}
        duration={300}
        height={isMobile ? height : 'auto'}
        id="menu"
        role="region"
      >
        {pages.map((page, index) => {
          const active = getBackgroundActive(page, index);
          const href = getPageHref({
            brandguide,
            isCname,
            page,
            search: window.location.search
          });
          return (
            <div
              className={classNames({
                nav__page: true,
                'page-name-wrapper': true
              })}
              key={page.key}
            >
              <Link
                className={classNames({
                  'nav-link': true,
                  'nav-link--page': true,
                  'nav-link--background-active': active
                })}
                href={href}
                onClick={() => {
                  handleRouting(page);
                }}
                scroll={false}
                shallow
              >
                {page.name}
              </Link>
              {renderSections(page, active)}
            </div>
          );
        })}
      </AnimateHeight>
    </nav>
  );
};

const StyledNav = styled(UnstyledNav)`
  grid-area: nav;
  padding: 30px 30px 0;

  &.nav--is-mobile {
    min-height: 80px;
    padding: 30px 30px 0 108px;
    position: relative;

    @media (min-width: 768px) {
      padding: 55px 30px 30px;
    }

    &.nav--prefers-reduced-motion {
      .nav--pages {
        transition: none !important;
      }
    }

    .nav__page {
      text-align: center;
      width: 100%;

      @media (min-width: 768px) {
        text-align: left;
        width: 200px;
      }
    }

    .nav-link {
      font-size: 20px;

      @media (min-width: 768px) {
        font-size: 16px;
      }
    }

    .nav-link--section {
      display: block;
      margin: 0 0 5px;

      @media (min-width: 768px) {
        display: inline-block;
        margin: 0 0 5px 25px;
      }
    }
  }

  @media (min-width: 768px) {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 55px 30px 30px;

    &.nav--sticky {
      max-height: 100vh;
      overflow-x: hidden;
      overflow-y: auto;
      position: sticky;
      top: 0;
    }
  }

  .nav__button {
    height: 48px;
    left: 30px;
    position: absolute;
    top: 30px;
    width: 48px;
    z-index: 1;

    @media (min-width: 768px) {
      display: none;
    }
  }

  .nav__page {
    margin-bottom: 15px;
    text-align: left;
    width: 200px;
  }

  .nav-link {
    display: block;
    font-size: 16px;
  }

  .nav-link--page {
    border-radius: 4px;
    font-weight: 700;
    letter-spacing: 0.5px;
    margin-bottom: 5px;
    padding: 5px 15px 5px 15px;
    text-decoration: none;
  }

  .nav-link--section {
    border-radius: 4px;
    display: inline-block;
    margin: 0 0 5px 25px;
    padding: 5px 10px;
    text-decoration: none;
  }

  .nav__sections {
    margin-top: 15px;
  }

  &.nav--collapse-page-sections {
    .nav__sections {
      ${screenReaderOnly}

      &.nav__sections--active {
        ${undoScreenReaderOnly}
        margin-top: 15px;
      }
    }
  }
`;

export const Nav: FunctionComponent<NavProps> = (props) => {
  return <StyledNav {...props} />;
};
